import React from 'react';
import DocumentTitle from 'react-document-title';
import { enquireScreen } from 'enquire-js';
import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';
import './static/style';
import layoutBg from '../assets/imgs/layoutBg.png';


let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});


class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
    activeHome: false,
    activeProduct: false,
    activefaq: false,
    isScroll: false,
    appUrl: '',
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);

    console.log(window.location.origin)
    fetch(window.location.origin + '/dcDescargar',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }



  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)
    if (scrollTop > 0) {
      this.setState({
        isBgWhite: true,
      });
    } else {
      this.setState({
        isBgWhite: false,
      });
    }

    // const homeOT = document.getElementById('home').offsetTop - 64;
    const producteOT = document.getElementById('product').offsetTop - window.innerHeight / 2;
    const faqOT = document.getElementById('FAQ').offsetTop - window.innerHeight / 2;
    if (scrollTop < producteOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: false,
      });
    } else if ( (producteOT < scrollTop) && (scrollTop < faqOT)) {
      this.setState({
        activeHome: false,
        activeProduct: true,
        activefaq: false,
      });
    } else if (scrollTop > faqOT) {
      this.setState({
        activeHome: false,
        activeProduct: false,
        activefaq: true,
      });
    }
  }
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === 'leave' });
  }
  jumpToSection = (id) => {
    let element = document.getElementById(id);
    if (element) {
      // 64 header高度
      window.scrollTo(0, (element.offsetTop - 64));
    }
  }
 
  render() {
    const {appUrl} = this.state
    const contentTip = '¡LE AYUDAMOS A OBTENER UN PRÉSTAMO MÁS FÁCILMENTE!'
    const renderLeftInfo = () => {
      return ([
        <Header key="header" 
          isScroll={this.state.isScroll} 
          activeHome={this.state.activeHome} 
          activeProduct={this.state.activeProduct} 
          activefaq={this.state.activefaq} 
          handleclick={this.jumpToSection} 
          isMobile={this.state.isMobile}
          className={this.state.isBgWhite ? 'btn-white' : ''}
        />,
        <Banner key="banner" isMobile={this.state.isMobile} navToShadow={this.navToShadow} />,
        <Footer key="footer" isMobile={this.state.isMobile}/>,
        <DocumentTitle  key="DameCaja" title="DameCaja" />,
      ])
    }
    const renderInfo = () => {
      return ([
        <div className={`downBoom`}>
        <div className={`subtitle`}>
            <div className={`desc desc1`}>{'En 4 pasos sencillos,obtenga su dinero en un plazo de 2 a 24 horas.'}</div>
        </div>
        <a target="_blank" rel="noopener noreferrer" href={appUrl} key="a" className={`word-download`}>
          Descargar DameCaja
        </a>
        </div>
       
      ])
    }
    return (
      <div className={this.state.isMobile ? 'boomBox-mobile' : 'boomBox'} >
        <div className={this.state.isMobile ? 'boom-mobile' : 'boom'} >
          {isMobile ? null : <div className={`contentTip`}>
            {contentTip}
          </div>}
          <div className={this.state.isMobile ? 'layoutLfet-mobile' : 'layoutLfet'}>
          {renderLeftInfo()}
          
          <div className={'bottom-bar-bom'} >
            </div>
          </div>
          <div className={this.state.isMobile ? 'bodyMob' : 'layout'}>
          {renderInfo()}
          {isMobile ? <img src={layoutBg} className='layoutBg' alt='layoutBg'/> : <div className={'bottom-bar'} >
              Copyright © 2024 | DameCaja | All Rights Reserved
            </div>}
          </div>
          
        </div>
      </div>);
      // return (
      //     <div className={this.state.isMobile ? 'bodyMob' : 'layout'}>
      //       {renderInfo()}
      //     </div>
      //   );
  }
}
export default Home;
