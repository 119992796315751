import React from "react";
import { enquireScreen } from "enquire-js";

import "../CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <h2>Política de Privacidad</h2>
        <p>
          Nos importa la privacidad de nuestros clientes,por lo que la presente
          Política de Privacidad (la “Política de Privacidad”) es un documento
          que tiene por finalidad informarle la regulación del tratamiento de la
          información y datos personales de los usuarios que acceden a la
          applicación y/o página web (en adelante, de manera conjunta, la
          “Plataforma”), cuya titularidad corresponde a DameCaja .
        </p>
        <p></p>
        <p>
          Esta Política de Privacidad ("Política") está incorporada y sujeta a
          los Términos y Condiciones. LEA ATENTAMENTE ESTA POLÍTICA DE
          PRIVACIDAD. ESTA POLÍTICA DESCRIBE LA FORMA EN QUE LA EMPRESA O SUS
          PROVEEDORES ("NOSOTROS", "NUESTRO", "DameCaja ") RECOGEN, ALMACENAN,
          ASEGURAN Y UTILIZAN SU INFORMACIÓN PERSONAL. USTED ACEPTA ESTA
          POLÍTICA DE PRIVACIDAD AL VISITAR NUESTRA PLATAFORMA O UTILIZAR
          CUALQUIERA DE NUESTROS SERVICIOS ("SERVICIO") A TRAVÉS DE LA
          PLATAFORMA. SI NO ACEPTA ESTA POLÍTICA DE PRIVACIDAD, NO PODRÁ
          UTILIZAR LOS SERVICIOS.
        </p>
        <p></p>
        <p>Su consentimiento</p>
        <p></p>
        <p>
          Al visitar esta aplicación móvil de DameCaja ("App") o el sitio web de
          DameCaja ("Sitio Web") (colectivamente, la "Plataforma") y hacer uso
          de los servicios proporcionados por nosotros, usted consiente
          expresamente que utilicemos y divulguemos su información personal de
          acuerdo con esta Política de Privacidad. Esta Política de Privacidad
          está incorporada y sujeta a los Términos y Condiciones de la
          Plataforma. A efectos de esta Política de Privacidad, los usuarios de
          los Servicios pueden ser clientes/consumidores, o cualquier otra
          persona que utilice los Servicios o acceda a nuestra Plataforma
          ("usuario" o "usted" o "su"). Si no está de acuerdo con esta Política
          o con alguna de sus partes, le rogamos que no utilice ni acceda a
          nuestra Plataforma ni a ninguna de sus partes.
        </p>
        <p></p>
        <p>
          Al afirmar su asentimiento a esta Política de Privacidad, usted da su
          consentimiento a dicha recopilación, uso, almacenamiento,
          procesamiento y divulgación según lo establecido en esta Política de
          Privacidad y en los Términos y Condiciones. Esta Política de
          Privacidad está sujeta a cambios en cualquier momento sin previo
          aviso. Usted es responsable de revisar regularmente esta Política de
          Privacidad para estar al tanto de cualquier cambio.
        </p>
        <p></p>
        <p>
          DameCaja se compromete a proteger su privacidad. La información
          recibida por DameCaja sólo se utilizará para proporcionarle el
          servicio y no se compartirá con ningún tercero o proveedor de
          servicios de terceros, excepto como se describe en esta Política de
          Privacidad.
        </p>
        <p></p>
        <p>NUESTRAS RESPONSABILIDADES CON LOS DATOS PERSONALES.</p>
        <p></p>
        <p>
          A partir de la Política de Privacidad, (en adelante el "DameCaja"),
          con domicilio en (DOMICILIO LOCAL DE LA EMPRESA) es responsable en los
          términos de la normativa de la recogida, almacenamiento, divulgación y
          uso, incluido el acceso, gestión, transmisión o eliminación de sus
          datos personales que recoge y, en general, de todos los datos
          personales que recogemos de nuestros usuarios (en adelante el/los
          "Titular/es").
        </p>
        <p></p>
        <p>Permisos que le solicitamos.</p>
        <p></p>
        <p>
          Le rogamos que lea atentamente lo siguiente para comprender nuestras
          opiniones y prácticas en relación con sus datos personales y cómo los
          trataremos.
        </p>
        <p></p>
        <p>1. SMS (registro de SMS)</p>
        <p></p>
        <p>
          DameCaja cargará y transmitirá los datos de sus SMS desde su teléfono
          a https://service.damecaja.com . Los SMS se utilizarán para
          gestionar los riesgos y realizar controles antifraude. Nombre de la
          contraparte/número de teléfono y texto del mensaje para que podamos
          leer el otp que enviamos a su teléfono y permitirle iniciar sesión en
          la aplicación automáticamente. DameCaja nunca compartirá los datos con
          terceros sin su permiso. Sólo se recopilarán bajo su consentimiento.
          Los datos serán transferidos y cargados a través de una conexión
          segura (https).
        </p>
        <p></p>
        <p>2. Datos de las aplicaciones instaladas</p>
        <p></p>
        <p>
          DameCaja recoge, almacena y monitoriza y sube a
          https://service.damecaja.com la información de los
          metadatos de cada aplicación instalada, incluyendo el nombre de la
          aplicación y del paquete, la hora de instalación y actualización, el
          nombre de la versión y el código de la versión de todas las
          aplicaciones instaladas en su dispositivo. Utilizamos esta información
          para evaluar su solvencia y evitar el fraude en los préstamos.
          DameCaja nunca compartirá los datos con terceros sin su permiso. Sólo
          se recopilarán bajo su consentimiento. Los datos serán transferidos y
          cargados a través de una conexión segura (https).
        </p>
        <p></p>
        <p>3. Datos del dispositivo</p>
        <p></p>
        <p>
          DameCaja recoge, almacena y sube a
          https://service.damecaja.com información específica sobre
          su dispositivo, incluyendo su modelo de hardware, memoria RAM,
          almacenamiento; identificadores únicos del dispositivo como IMEI,
          número de serie, SSAID; información de la SIM que incluye operador de
          red, estado de roaming, códigos MNC y MCC, información WIFI que
          incluye dirección MAC e información de la red móvil. Utilizamos estos
          datos para identificar de forma exclusiva los dispositivos y evitar el
          fraude. Esto también ayuda a enriquecer su perfil crediticio y a
          proporcionarle las mejores ofertas de préstamo de acuerdo con su
          perfil. DameCaja nunca compartirá los datos con terceros sin su
          permiso. Sólo se recopilarán bajo su consentimiento. Los datos serán
          transferidos y cargados a través de una conexión segura (https).
        </p>
        <p></p>
        <p>4. Almacenamiento</p>
        <p></p>
        <p>
          DameCaja requiere un permiso de almacenamiento para que su KYC y otros
          documentos relevantes puedan ser descargados y guardados de forma
          segura en su teléfono. A continuación, puede cargar fácilmente los
          documentos correctos relacionados con el KYC para rellenar más
          rápidamente los detalles de la solicitud de préstamo y el proceso de
          desembolso. Esto asegura que se le proporcione una experiencia sin
          problemas al utilizar la aplicación.
        </p>
        <p></p>
        <p>5. Cámara/Imagen</p>
        <p></p>
        <p>
          Necesitamos que proporciones imágenes de tu identificación y de ti
          mismo sosteniendo la identificación desde tu cámara. Solo subiremos
          las imágenes que elijas a nuestro servidor
          (https://service.damecaja.com) a través de un cifrado
          seguro. Las utilizamos para evaluar si la identificación es auténtica
          y válida, y si realmente eres tú quien está presentando la evaluación
          financiera.
        </p>
        <p></p>
        <p>6. Ubicación</p>
        <p></p>
        <p>
          Recogemos la información de localización de tu dispositivo y la
          subimos a https://service.damecaja.com para reducir el
          riesgo asociado a tu cuenta. Es necesario estar dentro de las
          fronteras de Chile para poder optar a nuestro servicio. DameCaja nunca
          compartirá los datos con terceros sin su permiso. Sólo se recopilarán
          bajo su consentimiento. Los datos serán transferidos y cargados a
          través de una conexión segura (https).
        </p>
        <p></p>
        <p>7.Datos de Contactos</p>
        <p>
          Necesitamos que seleccione manualmente el contactos de emergencia
          desde su lista de contactos incluido nombre y número de teléfono, esta
          función sirve para cuando no podemos poner en contacto
          contigo.Solamente los contactos seleccionados manualmente por los
          usuarios se transmitirán a nuestra aplicación de manera encriptada, y
          se guardarán por 1 año a partir de la fecha de carga. Nunca
          recopilaremos su información sin consentimiento. Si desea eliminar sus
          datos privados, puede enviar un correo electrónico a
          hola@damecaja.com. Envíe una solicitud al servicio de atención al
          cliente y podemos eliminarla manualmente dentro de (3-5 días hábiles).
        </p>
        <p></p>
        <p>8. DATOS PERSONALES RECOGIDOS PARA EL TRATAMIENTO.</p>
        <p></p>
        <p>
          Con lo anterior, le informamos que DameCaja obtendrá sus datos
          personales, ya sea directa o indirectamente, cuando usted realice una
          solicitud y proporcione sus datos personales para obtener servicios de
          crédito de DameCaja, a través de nuestra página web. aplicación nativa
          de Android ("App").
        </p>
        <p></p>
        <p>
          DameCaja se compromete a tratar sus siguientes Datos Personales con
          los medios antes mencionados:
        </p>
        <p></p>
        <p>a) Datos de identificación</p>
        <p></p>
        <p>b) Datos de ocupación</p>
        <p></p>
        <p>c) Datos financieros</p>
        <p></p>
        <p>d) Datos patrimoniales</p>
        <p></p>
        <p>e) Datos de su compra</p>
        <p></p>
        <p>
          f) Información de su teléfono móvil y/o Apps. Incluyendo, sin
          limitación, información relacionada con su servicio celular,
          incluyendo: (i) operador; (ii) pagos de servicios, servicios
          adicionales y/o recargas de saldo por servicio celular o servicios
          adicionales; (iii) hábitos de consumo; (iv) marca y modelo de teléfono
          móvil; y (v) Apps.
        </p>
        <p></p>
        <p>
          Estos datos pueden ser utilizados para evaluar la información del
          crédito que usted solicita, verificar su identidad, evaluar y
          calificar su capacidad crediticia y para la gestión de riesgos.
        </p>
        <p></p>
        <p>Seguridad de los datos</p>
        <p></p>
        <p>
          Todas las transmisiones están protegidas por una codificación SSL de
          128 bits. Los datos se transfieren y cargan a través de una conexión
          segura (https) al servidor: https://service.damecaja.com
        </p>
        <p></p>
        <p>Registro de datos</p>
        <p></p>
        <p>
          Queremos informarle de que siempre que utilice nuestro servicio, en
          caso de que se produzca un error en la aplicación, recopilamos datos e
          información (a través de productos de terceros) en su teléfono
          denominados Datos de registro. Estos Datos de Registro pueden incluir
          información como la dirección de Protocolo de Internet ("IP") de su
          dispositivo, el nombre del dispositivo, la versión del sistema
          operativo, la configuración de la app cuando utiliza mi Servicio, la
          hora y la fecha de su uso del Servicio y otras estadísticas.
        </p>
        <p></p>
        <p>Cookies</p>
        <p></p>
        <p>
          Las cookies son archivos con una pequeña cantidad de datos que se
          utilizan habitualmente como identificadores únicos anónimos. Se envían
          a su navegador desde los sitios web que visita y se almacenan en la
          memoria interna de su dispositivo.
        </p>
        <p></p>
        <p>
          Este Servicio no utiliza estas "cookies" explícitamente. Sin embargo,
          la aplicación puede utilizar código y bibliotecas de terceros que
          utilizan "cookies" para recopilar información y mejorar sus servicios.
          Usted tiene la opción de aceptar o rechazar estas "cookies" y saber
          cuándo se envía una "cookie" a su dispositivo. Si decide rechazar
          nuestras cookies, es posible que no pueda utilizar algunas partes de
          este Servicio.
        </p>
        <p></p>
        <p>Proveedores de servicios</p>
        <p></p>
        <p>
          Podemos emplear a empresas e individuos de terceros por las siguientes
          razones:
        </p>
        <p></p>
        <p>Para facilitar nuestro Servicio;</p>
        <p></p>
        <p>Para prestar el Servicio en nuestro nombre;</p>
        <p></p>
        <p>Para realizar servicios relacionados con el Servicio;</p>
        <p></p>
        <p>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</p>
        <p></p>
        <p>
          Queremos informar a los usuarios de este Servicio de que estos
          terceros tienen acceso a sus Datos Personales. El motivo es realizar
          las tareas que se les asignan en nuestro nombre. Sin embargo, están
          obligados a no divulgar ni utilizar la información para ningún otro
          fin. Enlace a la política de privacidad de los terceros proveedores de
          servicios utilizados por la app:
        </p>
        <p></p>
        <p>Google Play Services</p>
        <p></p>
        <p>Google Analytics for Firebase</p>
        <p></p>
        <p>Firebase Crashlytics</p>
        <p></p>
        <p>Facebook</p>
        <p></p>
        <p>Precauciones de seguridad</p>
        <p></p>
        <p>
          Nuestra plataforma tiene la intención de proteger su información
          personal y mantener su exactitud según lo confirmado por usted.
          Aplicamos medidas de seguridad físicas, administrativas y técnicas
          razonables para ayudarnos a proteger su información personal contra el
          acceso, el uso y la divulgación no autorizados. Por ejemplo,
          codificamos toda la información personal sensible cuando la
          transmitimos por Internet. También exigimos a nuestros proveedores de
          servicios registrados que protejan dicha información del acceso, uso y
          divulgación no autorizados. Nuestra plataforma cuenta con estrictas
          medidas de seguridad para proteger la pérdida, el uso indebido y la
          alteración de la información bajo control. Nos esforzamos por
          salvaguardar y garantizar la seguridad de la información proporcionada
          por usted. Utilizamos un sistema de encriptación basado en Secure
          Sockets Layers (SSL) para la transmisión de la información. Combinamos
          la seguridad en múltiples pasos dentro de nuestros productos con la
          tecnología más avanzada para garantizar que nuestros sistemas
          mantienen fuertes medidas de seguridad.
        </p>
        <p></p>
        <p>
          Nos esforzamos por proteger del acceso no autorizado, la alteración,
          la divulgación o la destrucción de la información que tenemos,
          incluyendo
        </p>
        <p></p>
        <p>
          a. Utilizamos la encriptación para mantener la privacidad de sus datos
          mientras están en tránsito;
        </p>
        <p></p>
        <p>
          b. Ofrecemos funciones de seguridad como la verificación OTP para
          ayudarle a proteger su cuenta;
        </p>
        <p></p>
        <p>
          c. Revisamos nuestras prácticas de recopilación, almacenamiento y
          procesamiento de información, incluidas las medidas de seguridad
          física, para evitar el acceso no autorizado a nuestros sistemas;
        </p>
        <p></p>
        <p>
          d. Restringimos el acceso a la información personal a nuestros
          empleados, contratistas y agentes que necesitan esa información para
          procesarla. Cualquier persona con este acceso está sujeta a estrictas
          obligaciones contractuales de confidencialidad y puede ser sancionada
          o despedida si no cumple con estas obligaciones;
        </p>
        <p></p>
        <p>
          e. Cumplimiento y cooperación con los reglamentos y las leyes
          aplicables;
        </p>
        <p></p>
        <p>
          f. Revisamos periódicamente esta Política de Privacidad y nos
          aseguramos de que procesamos su información de manera que se cumpla.
        </p>
        <p></p>
        <p>
          g. Cuando recibimos quejas formales por escrito, respondemos
          poniéndonos en contacto con la persona que ha presentado la queja.
          Trabajamos con las autoridades reguladoras correspondientes para
          resolver cualquier queja relativa a la transferencia de sus datos que
          no podamos resolver con usted directamente.
        </p>
        <p></p>
        <p>Conservación y eliminación de datos</p>
        <p></p>
        <p>
          DameCaja se compromete a proteger sus datos personales contra el
          acceso no autorizado, el uso indebido y la divulgación utilizando las
          medidas de seguridad adecuadas según el tipo de datos y la forma en
          que los procesamos. Conservamos la información sobre usted para
          proporcionarle una experiencia sin problemas, para ponernos en
          contacto con usted en caso de que necesite ayuda y sobre su cuenta,
          para detectar, mitigar, prevenir e investigar actividades fraudulentas
          o ilegales. Conservamos sus datos durante el tiempo necesario para
          ofrecerle nuestros servicios. También podemos conservar y utilizar sus
          datos personales según sea necesario para cumplir con nuestras
          obligaciones legales, resolver conflictos y hacer cumplir nuestros
          acuerdos. Sin perjuicio de lo dispuesto en este apartado, Si desea
          dejar de usar nuestro servicio y eliminar su cuenta, ingrese a
          https://www.damecaja.com/borrar para enviar una solicitud de
          eliminación, le pediremos que proporcionar información de su cuenta y
          pruebas relacionadas para verificar y validar su identidad; Sin
          embargo, es posible que no pueda utilizar nuestros servicios después
          de la eliminación.{" "}
        </p>
        <p></p>
        <p>Sus reechoes</p>
        <p></p>
        <p>
          Utilizaremos sus datos para elaborar estadísticas relacionadas con
          nuestra base de usuarios o nuestra cartera de préstamos y podremos
          revelar dicha información a cualquier tercero para tales fines,
          siempre y cuando dicha información sea siempre anónima.
        </p>
        <p></p>
        <p>
          Si deseamos utilizar sus datos con fines de marketing, le informaremos
          antes de hacerlo. Usted tendrá derecho a impedir dicho uso
          informándonos, en un plazo de 10 días desde que se le informe del uso
          propuesto, de que no desea revelar dicha información. También puede
          ejercer el derecho en cualquier momento poniéndose en contacto con
          nosotros en hola@damecaja.com
        </p>
        <p></p>
        <p>Contacto con nosotros</p>
        <p></p>
        <p>
          Si tiene alguna pregunta o sugerencia sobre esta Política de
          Privacidad, no dude en ponerse en contacto con nosotros:
        </p>
        <p></p>
        <p>DameCaja</p>
        <p></p>
        <p>Correo electrónico: hola@damecaja.com</p>
        <p></p>
        <p>Horario de oficina: De lunes a sábado de 8:30 a 17:00</p>
        <p></p>
        <p>Página web de la empresa：https://www.damecaja.com</p>
      </div>
    );
  }
}
export default PrivacyAgreement;
